import {turpial} from "./turpial";

const uchatframe_importer = function(callback){
    if(typeof uchatframe_instance !== "undefined"){
        callback();
        return;
    }

    window.uchatframe = null;
    turpial.fetch({
        url: ["https://cdn.jsdelivr.net/gh/3pod-us/3pod-us.github.io@master/widgets/uchat/uchatframe.js"],
        ready: ()=>{
            window.uchatframe = new uchatframe_instance();
            window.uchatframe.create();
            callback();
        },
    })
}

export { uchatframe_importer }