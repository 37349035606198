
import { writable } from 'svelte/store';
import { RouterStore } from '../RouterStore';
import { UserInterface } from '../models/UserInterfaces';
import { SubscriptionsInterface } from '../models/SubscriptionInterface';
import { turpial, Turpial } from './turpial';



const turnstile_pk = Turpial.$WindowObject?.IS_ONLINE ? "0x4AAAAAAAz1-qtOEEVsM45z" : "0x4AAAAAAALZr-x-tz7KsQDe";

function appData() {

    let application_data = {
        main_components_loaded: false,
        is_online: false,

        /**
         * lleva a la ruta /
        */
        host: "",

        /**
         * lleva a la ruta /api/v1/services
        */
        api_host: "",

        /**
         * lleva a la ruta /
        */
        api_host_base: "",

        /**
         * host for stream.3pod.io/api/v1/services/
         */
        api_stream: "",

        /**
         * host for stream.3pod.io/
         */
        api_stream_base: "",

        /**
         * current version for user
         */
        version_user: null, // 1.2, presentation, 2.0,

        /**
         * lleva a la ruta default/public/assets/
         */
        assets_path: "",
        enviroment: "", //["localhost","dev","production"]
        authenticate: "", 
        website_title: "",
        is_testing_user: null,
        plan_limits_testing: "",
        go_to_plans: "#",
        projects: [],
        path: "/",
        stripeTokens: { 
            live: "pk_live_ZodCkKBak1MPlwBJsxsMuubx",
            test: "pk_test_X7AvKFxbFhE5jaMPKPqiAbql003mxvgpKe",
        },
        stripeTokens_v2: { 
            //live: "pk_live_51OEXyaGqoorrE7eC4Qtor2u3YWGCqZkxiPGBk9NZf7QMWCy0KBZlohfpoHaKYkWEiZMYFGWbtlP9kLSt6LRsuEPE00vwPdGIjS",
            //test: "pk_test_51OEXyaGqoorrE7eCLdOu0IfVLrKTAyUZeFunq7vgyE84beauILQ1yYT1QCB2otAx3nJioglxBPsR60x4rqZP7rCB00J9IP9O7v",

            // RE-DEFINE TO USE stripeTokens from V1 for some days.
            live: "pk_live_ZodCkKBak1MPlwBJsxsMuubx",
            test: "pk_test_X7AvKFxbFhE5jaMPKPqiAbql003mxvgpKe",
        },
        stripe_key: null, // use this for StripeCard
        
        /**
         * @type {UserInterface}
         */
        user: null,
        admin: null,
        superuser: null,
        host: null,
        loaded_turnstile: false,
        turnstile_token: null,
        show_onboarding: false,
        on_error_session_redirect: {
            user: null,
            admin: null,
            superuser: null,
        }
	}

	const { subscribe, update } = writable(application_data);
	
	const dataApp = {
        subscribe: subscribe,
        create_turnstile_instance: function(selector, callback, props = {}){
            selector = selector || "#turnstile-element";
            callback = callback || function(){};
            let turnstile_id = turnstile.render(selector, {
                sitekey: turnstile_pk,
                //retry: props.retry ?? "auto",
                callback: function(turnstile_token) {
                    update((data)=>{
                        data.turnstile_token = turnstile_token;
                        return data;
                    })
                    callback();
                    setTimeout(()=> turnstile.remove(turnstile_id), 2000);
                },
            });
        },
        verify_user_version: () => {
            update((data) => {
                let metadata_3pod_2_0 = data?.user?.metadata["3pod_2_0"] ?? null;
                let version_to_show = metadata_3pod_2_0 ? "2.0" : "1.2";

                if(data?.version_user === null && metadata_3pod_2_0 === null) {
                    let subscription_funnel = null;
                    let subscription_suite = null;

                    data?.user?.subscriptions?.forEach((sub)=>{
                        if(sub.membership_type === "funnels"){
                            subscription_funnel = sub;
                        }
                        if(sub?.membership_type === "suite") {
                            subscription_suite = sub;
                        }
                    });
                    version_to_show = subscription_funnel === null ? "2.0" : "1.2";              
                    data.version_user = version_to_show;

                    if(subscription_suite) {
                        data.version_user = "2.0";
                    }
                    return data;
                }
                if(data?.version_user === null) {
                    data.version_user = version_to_show;
                    return data;
                }
                return data;
            });
        },
        is_testing: function(only_tag = false, tag_testing = "testing3pod")
        {
            if(application_data.is_testing_user === true){
                return true;
            }

            let user = application_data?.user ?? {};
            let tags = [];
            
            if(user?.user_from_campaign !== null){
                tags.push(user?.user_from_campaign ?? "");
            }

            if(typeof user?.more_tags === "string"){
                user.more_tags = user.more_tags.split(",");
            }

            if(Array.isArray(user?.more_tags)){
                user.more_tags.forEach((tag)=>{
                    tags.push(tag)
                })
            }

            tags.forEach((tag)=>{
                if(Array.isArray(tag_testing)){
                    tag_testing.forEach((tag_test)=>{
                        if(tag === tag_test || tag === "testing3pod"){
                            application_data.is_testing_user = true;
                        }
                    })
                }else{
                    if(tag_testing === tag){
                        application_data.is_testing_user = true;
                    }
                }
                
            })

            if(only_tag === true){
                return application_data.is_testing_user === true;
            }

            return window.IS_ONLINE === false || application_data.is_testing_user === true;
        },
        /**
         * Get Certain Membership.
         * @param {String} membership_type 
         * @param {UserInterface} user 
         * @returns 
         */
        get_subscription: function(membership_type, user = null){
            user = user ?? application_data?.user ?? {};
            if(user == null){
                user = window.user ?? window.user2
            }
            let subscriptions = user?.subscriptions ?? [];
            let subscription = subscriptions.find(function(subscription){
                return subscription.membership_type === membership_type;
            }) ?? null;

            return subscription;
        },
        /**
         * 
         * @param {UserInterface} User 
         * @param {String} membership_type 
         * @returns Object
         */
        get_subscription_status: (User, membership_type)=>{
            if(User == null){
                User = window.user ?? window.user2
            }
            /**
             * @type {SubscriptionsInterface}
             */
            let subscription = {};
            /**
             * @type {Array.<SubscriptionsInterface>}
             */
            let subscriptions = User?.subscriptions ?? [];
            

            subscription = subscriptions.find((item) => {
                return item.membership_type === membership_type;
            })

            /**
             * @type {SubscriptionsInterface}
             */
            let subscription_life_save = null;
            subscription_life_save = subscriptions.find((item) => {
                return item.membership_type === "lifesaving";
            })

            let sub_status = subscription?.status ?? null;
            let lifesaving_status = subscription_life_save?.status ?? null;

            let result = {
                subscription: subscription,
                status_sub: sub_status,
                lifesaving_status: lifesaving_status,
                show_notification: null,
                block_elements: false,
            }

            if((sub_status === "canceled" || sub_status === null) && lifesaving_status !== "active"){
                result.show_notification = "show_warning_delete";
                result.block_elements = true;
            }

            if((sub_status === "canceled" || sub_status === null) && lifesaving_status === "active"){
                result.show_notification = "show_info_lifesaving";
                result.block_elements = true;
            }

            if((sub_status !== "canceled" && sub_status !== null)){
                result.show_notification = null;
                result.block_elements = false;
            }


            if((membership_type === "suite" || membership_type === "funnels") && (subscription ?? null) !== null){
                let benefits = subscription.plan.benefits;

                result.max_funnels = 5;
                result.max_products = 100;
                result.max_leads = 50000;
                result.max_forms = 50000;
                result.max_orders = 50000;
                result.max_sessions = 50000;

                function search_value(field){
                    if(typeof benefits[field] !== "undefined"){
                      return benefits[field].is_unlimited ? Infinity : benefits[field].value
                    }
                    return null;
                }

                result.max_funnels = search_value("embudos") ?? result?.max_funnels ?? 0;
                result.max_products = search_value("productos") ?? result?.max_products ?? 0;
                result.max_orders = search_value("ordenes_al_mes") ?? result?.max_orders ?? 0;
                result.max_leads = search_value("leads_por_mes") ?? result?.max_leads ?? 0;
                result.max_forms = search_value("formularios_por_mes") ?? result?.max_forms ?? 0;
                result.max_sessions = search_value("sesiones_por_mes") ?? result?.max_sessions ?? 0;
                
            }

            // set this to avoid low quantities for some fields i case of sub funnels 1.2
            if(membership_type === "funnels"){
                result.max_funnels = 5;
                result.max_products = 100;
                result.max_leads = 50000;
                result.max_forms = 50000;
                result.max_orders = 50000;
                result.max_sessions = 50000;
            }
            let tags = User?.more_tags ?? "";
            if(typeof tags === "string"){
                tags = (User?.more_tags ?? "").split(",");
                tags.push(User?.user_from_campaign ?? "");
            }
            

            tags.forEach(function(tag){
                if(tag === "testing3pod"){
                    result.max_funnels = 10000;
                    result.max_products = 9000;
                    result.max_leads = 50000;
                    result.max_forms = 50000;
                    result.max_orders = 50000;
                    result.max_orders = 50000;
                    result.max_sessions = 50000;
                }
            })
            
            return result;
        },
        /**
         * 
         * @param {UserInterface} user
         */
        get_websites_subscription: (user)=>{
            let subscriptions = user?.subscriptions ?? [];
            let subscription = null;
            subscriptions.forEach((sub)=>{
                if(sub.membership_type === "funnels"){
                    subscription = subscription;
                }
            })
            subscriptions.forEach((sub)=>{
                if(sub.membership_type === "suite" && subscription === null){
                    subscription = sub;
                }
            })


            return subscription;
        },
        /**
         * 
         * @param {UserInterface} User 
         * @returns Object
        */
        get_suite_sub: function(User = null){
            if(User == null){
                User = window.user ?? window.user2;
            }
            const app = this;
            let sub_statuses_funnels = ApplicationData.get_subscription_status(User, "funnels");
            let sub_statuses = ApplicationData.get_subscription_status(User, "suite");
            let subscription_website = ApplicationData.get_websites_subscription(User);

            let block_buttons = false;

            if(sub_statuses_funnels.show_notification  === "show_warning_delete"){
                if(sub_statuses.show_notification === "show_warning_delete"){
                    block_buttons = true;
                }
            }else{
                sub_statuses = sub_statuses_funnels;
            }

            return {sub_statuses, block_buttons, subscription_website}
        },
        /**
         * 
         * @param {UserInterface} User 
         * @returns Object
         */
        get_all_subs_status: (User)=>{    
            if(User == null){
                User = window.user ?? window.user2
            }     
            /**
             * @type {Array.<SubscriptionsInterface>}
             */   
            let subscriptions = User.subscriptions;
            let has_at_least_one_active = false;
            let has_trialing_active = false;
            let all_canceled = false;
            let show_notification = null;
            let has_lifesaving = false;
            let block_elements = false;
            subscriptions.map((subscription)=>{
                if(subscription.membership_type !== "lifesaving"){
                    if(subscription.status === "active" || subscription.status === "trialing" || subscription.status === "past_due"){
                        has_at_least_one_active = true;
                    }
                    if(subscription.status === "trialing"){
                        has_at_least_one_active = true;
                    }
                }
                
                if(subscription.membership_type === "lifesaving"){
                    if(subscription.status === "active"){
                        
                        has_lifesaving = true;
                    }
                }
            })

            function set_all_canceled(){
                all_canceled = true;
                block_elements = true;
                show_notification = "show_warning_delete";

                if(has_lifesaving){
                    show_notification = "show_info_lifesaving";
                }
            }

            if(!has_at_least_one_active && subscriptions.length > 0){
                set_all_canceled();
            }

            if(subscriptions.length === 0){
                set_all_canceled();
            }

            let results = {
                all_canceled: all_canceled,
                has_at_least_one_active: has_at_least_one_active,
                canceled__has_lifesaving: (all_canceled && has_lifesaving),
                canceled__no_lifesaving: (all_canceled && has_lifesaving === false),
                has_lifesaving: has_lifesaving,
                has_trialing_active: has_trialing_active,
                show_notification: show_notification,
                block_elements: block_elements,
            }
            
            return results

        },
        
        use_turnstile_token: function(callback){
            callback = callback || function(){};
            update((data)=>{
                if(data.turnstile_token === null){
                    console.warn("Probably the previus instance was already used, create a new one.");
                }
                callback(`${data.turnstile_token}`);
                data.turnstile_token = null;
                return data;
            })
        },
        addMethod: function(field, func){
            if(typeof func !== "function"){
                throw("Error, '"+field+"' is not a function");
            }
            update(( data )=>{
                data.methods[field] = func;
                return data;
            })
        },
        validateAdminPermission: function (permission){
            try {
                if(RouterStore.get().controller_name === "superuser"){return true;}
                return ApplicationData.get().admin.roles.some((item)=> item === permission)
            } catch (error) {
                console.error(error);
                return false;
            }
        },
        updateObject: update,
        update: function(field, new_data){            
            update(( data )=>{
                data[field] = new_data;
                return data;
            })
        },
        get: function(){
            return application_data;
        }
    }

    return dataApp;
}


const ApplicationData = appData();
export {ApplicationData, turnstile_pk}
