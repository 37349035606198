import { ApplicationData } from "../ApplicationData";

function isSupportButtonDisabled(date) {
    // Get the current day of the week (0 for Sunday, 6 for Saturday)
    const day = date.getDay();
    
    // Get the current hour (0-23)
    const hour = date.getHours();

    // get current minutes 0-60
    const minutes = date.getMinutes();

    // Check if it's within business hours on weekdays
    if(day >= 1 && day <= 5){ 
        // Check if it's within business hours on weekdays
        if(hour >= 7 && (hour + (minutes/60) < 19.50)){
            return false; // Button enabled on weekdays between 9AM and 7:30PM EST
        }
    }
    
    // Check if it's within business hours on Saturday
    if (day === 6 && hour >= 8 && hour < 12) {
        return false; // Button enabled on Saturday between 9AM and 6PM EST
    }

    // on sunday: block.
    if(day > 6){
        return false;
    }
    
    // All other cases, button is disabled
    return true;
}

function isAvailableSupportText(){
    let content = "Lun-Vie 9:00AM-7:30PM, Sáb 8:00AM-12:00PM EST"

    if(ApplicationData.get().user.lang !== "es"){
        content = "Mon-Fri 09:00-19:30, Sáb 08:00-12:00 EST"
    }

    return content;
}

window.isSupportButtonDisabled = isSupportButtonDisabled;

export {
    isSupportButtonDisabled,
    isAvailableSupportText
}