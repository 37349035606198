// bulma docs https://dev.to/1n0t/creacion-de-un-proyecto-svelte-bulma-sass-1o8i

import { turpial } from '../libraries/turpial';
import { ShadowRoot } from '../libraries/ShadowRoot';
import App from '../Highlevelchat/Highlevelchat.svelte';
let app = document.querySelector(".highlevel-chat");
//let mounted = turpial.mount(app, `<plans-module class="svelte-app-child index-module"></plans-module>`);
//console.log(mounted)


//app = mounted.attachShadow({mode: 'open'});

let application = new App({
	target: app,
});
//ShadowRoot.update(app.querySelector(".svelte-module"));
//let styles = document.querySelector("#injected-css-sv");
//app.appendChild(styles);

//export default app;